import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import { format as dateFnsFormat, parse } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import UiContext from "../../../../store/ui-context";
import WarehouseSelectorDialog from "../../../../dialogs/selectors/WarehouseSelectorDialog";
import { useDispatch } from "react-redux";
import { Paginator } from "primereact/paginator";
import { setLayoutDashboardTitle } from "../../../../store/ui-slice";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import BirthdayNoteDialog from "./BirthdayNoteDialog";

const title = "Customer Birthday";
const additionalParams = {
  with_customer: 1,
};
const order = "date";
const order_method = "ASC";

const BirthdayPage = () => {
  const uiContext = useContext(UiContext);
  const toast = useRef();
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);

  const refFilter = useRef(null);

  const [listData, setListData] = useState([]);
  const [listBC, setListBC] = useState([]);

  const [selectedCustomerBirthday, setSelectedCustomerBirthday] =
    useState(null);

  const [showCustomerNoteDialog, setShowCustomerNoteDialog] = useState(false);

  // const firstDateOfMonth = new Date();
  // firstDateOfMonth.setDate(1);
  // const lastDateOfMonth = new Date();
  // lastDateOfMonth.setMonth(lastDateOfMonth.getMonth() + 1);
  // lastDateOfMonth.setDate(0);

  const [selectedDate, setDate] = useState([new Date(), new Date()]);
  const [selectedBC, setSelectedBC] = useState(null);

  const { requestGet, requestPost, loading } = useAPIRequest();

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
    reloadDataBC();
  }, []);

  const generateFilter = () => {
    let filter = "";

    const filterText = refFilter.current.value.trim();

    if (filterText && filterText.length > 0) {
      filter += `data_customer.name:contains:%${filterText}%`;
    }

    if (selectedBC && selectedBC.id > 0) {
      if (filter.length > 0) filter += ";";
      filter += `data_customer.bc_id:=:${selectedBC.id}`;
    }

    let dateStart = selectedDate[0];
    let dateEnd = selectedDate[1];

    if (dateStart) {
      if (filter.length > 0) filter += ";";
      filter += `date:>=:${dateFnsFormat(dateStart, "yyyy-MM-dd")}`;
    }

    if (dateEnd) {
      if (filter.length > 0) filter += ";";
      filter += `date:<=:${dateFnsFormat(dateEnd, "yyyy-MM-dd")}`;
    }

    // if (filter.length > 0) filter += ";";
    // filter += `data_product.product_type:=:2`;

    return filter;
  };

  const reloadDataBC = async () => {
    setListBC([]);
    const params = {
      page: 1,
      take: 999999,
      order: "name",
      order_method: "ASC",
      filter: "user_type:=:3;retire_date:=:Null",
    };

    return requestGet({
      fullUrl: "api/masterdata/employee/data",
      params,
      onSuccess: ({ pagination, message, data }) => {
        const defaultBCOptions = { id: 0, name: "* All BC *" };
        setListBC([defaultBCOptions, ...data]);
        setSelectedBC(defaultBCOptions);

        reloadData({ page: 1, take: 999999, listBCs: data });
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });
  };

  const reloadData = async ({ page, take, listBCs }) => {
    if (loading) return;

    setListData([]);

    const filter = generateFilter();

    const params = {
      page: page,
      take: take,
      filter,
      ...additionalParams,
      order,
      order_method,
    };

    const newListBC = listBCs ?? listBC;

    return requestGet({
      fullUrl: "api/customer/birthdayhistory/data",
      params,
      onSuccess: ({ pagination, message, data }) => {
        const newList = [];

        const page = pagination["page"];
        const take = pagination["take"];
        const startNumber = (page - 1) * take;

        const mapIdToBC = {};
        newListBC.forEach((item) => {
          mapIdToBC[item.id] = item;
        });

        const birthdayDateVariant = [];

        data.forEach((item, index) => {
          const newItem = { ...item };
          newItem["no"] = startNumber + index + 1;
          newItem["code"] = item.data_customer ? item.data_customer.code : "-";
          newItem["name"] = item.data_customer ? item.data_customer.name : "-";
          newItem["birthday"] = item.date;
          const bcId = item.data_customer ? item.data_customer.bc_id : null;
          newItem["bc_name"] = mapIdToBC[bcId] ? mapIdToBC[bcId].name : "-";

          if (item.date !== null) {
            const dateText = dateFnsFormat(new Date(item.date), "yyyy/MM/dd");
            if (!birthdayDateVariant.includes(dateText)) {
              birthdayDateVariant.push(dateText);
            }
          }

          newList.push(newItem);
        });

        if (birthdayDateVariant.length === 1) {
          setExpandedRows([newList[0]]);
        }

        setListData(newList);
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });
  };

  const handleChangeBirthdayNote = (note) => {
    if (loading) return;

    const body = { ...selectedCustomerBirthday, description: note };
    requestPost({
      fullUrl: "api/customer/birthdayhistory/save",
      body,
      onSuccess: ({ message, data }) => {
        uiContext.showSuccessMsg("Success", message);
        const newList = [...listData];
        const index = newList.findIndex(
          (el) => el.id === selectedCustomerBirthday.id
        );
        if (index > -1) {
          newList[index] = { ...newList[index], ...data };
          setListData(newList);
        }
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });
    setShowCustomerNoteDialog(false);
  };

  const handleReload = () => {
    reloadData({ page: 1, take: 999999 });
  };

  //   const handleOnPage = (e) => {
  //     const newPage = e.first / paging.take + 1;
  //     const newTake = e.rows;

  //     setPaging({ ...paging, take: newTake, page: newPage });
  //     reloadData({ page: newPage, take: newTake });
  //   };

  const handleEnterFilter = (e) => {
    const key = e.key;
    if (key === "Enter") {
      reloadData({ page: 1, take: 999999 });
      //   reloadData({ page: 1, take: paging.take });
    }
  };

  const handleClickCustomerDetail = (row) => {
    const url = `${process.env.REACT_APP_MY_URL}/transaction/customerdata/${row.customer_id}`;
    window.open(url, "_blank").focus();
  };

  const handleClickCustomerNote = (row) => {
    setSelectedCustomerBirthday(row);
    setShowCustomerNoteDialog(true);
  };

  const calculateCustomerCount = (birthday) => {
    return listData.filter((el) => el.birthday === birthday).length;
  };

  const customColumns = [];

  const headerTemplate = (data) => {
    return (
      <React.Fragment>
        <span className="vertical-align-middle ml-2 font-bold line-height-3">
          {data.birthday}
        </span>
      </React.Fragment>
    );
  };

  const footerTemplate = (data) => {
    return (
      <React.Fragment>
        <td colSpan={5}>
          <div className="row-end-end font-bold w-full">
            Birthday Customers: {calculateCustomerCount(data.birthday)}
          </div>
        </td>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (row) => {
    return (
      <div className="row-center-center gap-2">
        <Button
          icon="pi pi-ellipsis-v"
          className="max-h-[1.7rem] max-w-[1.7rem] p-0"
          outlined
          rounded
          pt={{
            icon: { className: "!text-[0.7rem]" },
          }}
          onClick={(e) => {
            e.preventDefault();
            handleClickCustomerDetail(row);
          }}
        />
        <Button
          icon="pi pi-pencil"
          className="max-h-[1.7rem] max-w-[1.7rem] p-0"
          outlined={row.description ? false : true}
          rounded
          pt={{
            icon: { className: "!text-[0.7rem]" },
          }}
          onClick={(e) => {
            e.preventDefault();
            handleClickCustomerNote(row);
          }}
        />
      </div>
    );
  };

  const customerBodyTemplate = (row) => {
    const { code, name } = row;
    return (
      <div className="col-start-start gap-0 ">
        <span className="text-xs font-bold text-slate-400 tracking-wider">
          {code}
        </span>
        <span>{name}</span>
      </div>
    );
  };

  const descriptionBodyTemplate = (row) => {
    const { edit_by, description, updated_at } = row;
    return (
      <div className="col-start-start gap-0 ">
        <span>{description}</span>
        {edit_by && updated_at && (
          <span className="text-xs text-slate-400 tracking-wide italic">
            by {edit_by.toUpperCase()} at{" "}
            {dateFnsFormat(new Date(updated_at), "yyyy/MM/dd HH:mm")}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <BirthdayNoteDialog
        visible={showCustomerNoteDialog}
        loading={loading}
        data={selectedCustomerBirthday}
        onHide={() => {
          setShowCustomerNoteDialog(false);
        }}
        onConfirm={handleChangeBirthdayNote}
      />
      <Toast ref={toast} />
      <div></div>
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20`}
      >
        <div className="row-between-center gap-4">
          <Calendar
            id="selectedDate"
            disabled={loading}
            value={selectedDate}
            showButtonBar
            selectionMode="range"
            hourFormat="24"
            showIcon
            onChange={(e) => {
              setDate(e.value);
            }}
            className={`dateInput w-[17rem]`}
            locale="id"
          />
          <Dropdown
            className="w-[12rem]"
            loading={loading}
            value={selectedBC}
            onChange={(e) => setSelectedBC(e.value)}
            options={listBC}
            optionLabel="name"
            placeholder="Select BC"
          />{" "}
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <i className="pi pi-search"></i>
            </span>
            <InputText
              ref={refFilter}
              disabled={loading}
              className="w-[12rem]"
              placeholder="Find Customer"
              onKeyDown={handleEnterFilter}
            />
          </div>
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-file-excel"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-print"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          />
        </div>
      </div>

      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <DataTable
            className="w-full"
            value={listData}
            rowGroupMode="subheader"
            groupRowsBy="birthday"
            sortMode="single"
            sortField="birthday"
            sortOrder={1}
            expandableRowGroups
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowGroupHeaderTemplate={headerTemplate}
            rowGroupFooterTemplate={footerTemplate}
            showGridlines
            stripedRows
            cellClassName="!py-2"
          >
            <Column field="no" header="No"></Column>
            <Column field="#" header="" body={actionBodyTemplate}></Column>
            <Column field="bc_name" header="BC"></Column>
            <Column
              field="code"
              header="Customer"
              body={customerBodyTemplate}
            ></Column>
            <Column field="birthday" header="Birthday"></Column>
            <Column
              field="description"
              header="Description"
              body={descriptionBodyTemplate}
            ></Column>
          </DataTable>
          {/* <Paginator
            first={paging.take * (paging.page - 1)}
            rows={paging.take}
            totalRecords={paging.total}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageChange={handleOnPage}
          /> */}
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default BirthdayPage;
