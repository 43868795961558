import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import TableAutoComponent from "../../../../../components/complex/crud/TableAutoComponent";

import { ProgressSpinner } from "primereact/progressspinner";
import EditorDialog from "../StockInDetailEditorDialog";
import useStockInEditorPage from "./useStockInEditorPage";
import WarehouseSelectorDialog from "../../../../../dialogs/selectors/WarehouseSelectorDialog";
import { useLocation, useNavigate } from "react-router-dom";

const StockInEditorPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    toast,
    loading,
    addDialogSelector,
    detailEditorValue,
    setDetailEditorValue,
    detailEditorError,
    setDetailEditorError,
    showDetailEditorDialog,
    setShowDetailEditorDialog,
    handleSaveDetail,
    elements,
    handleAdd,
    headerValue,
    validation,
    convertSaveBody,
    handleSave,
    handleClose,
    handleReload,
    selectedRows,
    handleSelectionChange,
    listData,
    customHandleEdit,
    sideMenuIsVisible,
    tableConfigs,
    handleDeleteDetail,
    generateSelectorDialog,
  } = useStockInEditorPage();

  generateSelectorDialog({
    field: "warehouse_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <WarehouseSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  const customColumns = [];

  const handleBack = () => {
    const accept = async () => {
      const urlParams = new URLSearchParams(location.search);
      const key = urlParams.get("key");
      if (key) {
        navigate(`..?key=${encodeURIComponent(key)}`);
      } else {
        navigate("..");
      }
    };

    const reject = () => {};

    confirmDialog({
      message: `Menutup bon ini, lanjutkan?`,
      header: "Konfirmasi ",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      acceptLabel: "Konfirmasi",
      rejectLabel: "Tidak",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  return (
    <div className={`col-start-start p-0 gap-4 w-100 px-4`}>
      <Toast ref={toast} />

      <ConfirmDialog />

      {addDialogSelector}

      <ConfirmDialog />

      <EditorDialog
        value={detailEditorValue}
        setValue={setDetailEditorValue}
        error={detailEditorError}
        setError={setDetailEditorError}
        visible={showDetailEditorDialog}
        onHide={() => setShowDetailEditorDialog(false)}
        onSave={handleSaveDetail}
      />

      <div></div>
      <div className="elevated-card-no-padding w-100 row-start-start relative wrap gap-8">
        <div className="row-start-start wrap gap-3 w-100 p-[1rem]">
          <div className="col-center-start ">{elements.number}</div>
          <div className="col-center-start min-w-[15rem]">
            {elements.warehouse_id}
          </div>
          <div className="col-center-start ">{elements.transaction_date}</div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
      <div className={`elevated-card row-between-center w-100 wrap`}>
        <div className="row-start-center gap-2">
          <Button
            className="max-h-[2.5rem] min-w-[3rem]"
            icon="pi pi-arrow-left"
            severity="danger"
            onClick={handleBack}
          />{" "}
          <Button
            icon="pi pi-plus"
            label="Tambah Baru"
            loading={loading}
            onClick={handleAdd}
            disabled={!headerValue || !headerValue.id || headerValue.id < 1}
          />
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-save"
            size="small"
            loading={loading}
            severity="success"
            onClick={() => {
              const valid = validation();
              if (valid) {
                const body = convertSaveBody();
                handleSave(body);
              }
            }}
            rounded
          />
          <Button
            icon="pi pi-print"
            size="small"
            loading={loading}
            severity="success"
            disabled
            rounded
          />
          <Button
            icon="pi pi-times"
            size="small"
            loading={loading}
            onClick={handleClose}
            rounded
            outlined
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            rounded
            outlined
          />
        </div>
      </div>

      <div className={`elevated-card row-start-start w-100 wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <TableAutoComponent
            showControlButton={true}
            selection={selectedRows}
            onSelectionChange={handleSelectionChange}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            onEditRow={async (row) => customHandleEdit(row)}
            onDeleteRow={(row) => handleDeleteDetail(row)}
            customColumns={customColumns}
          />
        </div>
      </div>

      <div className="h-[30rem]"></div>
      <div className="h-[13.1rem]"></div>
    </div>
  );
};

export default StockInEditorPage;
