import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import UiContext from "../store/ui-context";

const ScanQRDialog = forwardRef((props, ref) => {
  const uiContext = useContext(UiContext);
  const refInput = useRef(null);
  const { visible, data, onHide, onConfirm, loading, title, forceable } = props;

  const [qrCode, setQrCode] = useState("");

  useImperativeHandle(ref, () => ({
    clearBarcode: () => {
      setQrCode("");
    },
    focusInput: () => {
      refInput.current.focus();
    },
  }));

  useEffect(() => {
    if (visible) {
      setQrCode("");
    }
  }, [visible]);

  const confirm = (event, force) => {
    const barcode = qrCode.trim();

    if (force) {
      onConfirm("**FORCE-SIGN-OUT**");
      return;
    }
    if (barcode && barcode.length > 0) {
      onConfirm(barcode);
    } else {
      uiContext.showErrorMsg("Error", "Please input a valid barcode");
      setQrCode("");
      refInput.current.focus();
    }
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      confirm(event);
    }
  };

  const handleClickInsert = (event) => {
    event.preventDefault();
    confirm(event);
  };

  const handleClickForceInsert = (event) => {
    event.preventDefault();
    confirm(event, true);
  };

  const dialogFooter = (
    <React.Fragment>
      <div className="row-between-start w-full">
        {forceable && (
          <Button
            label="Force Process"
            icon="pi pi-save"
            loading={loading}
            onClick={handleClickForceInsert}
          />
        )}
        {!forceable && <div />}
        <div className="row-end-end">
          <Button
            label="Cancel"
            icon="pi pi-times"
            outlined
            onClick={props.onHide}
          />
          <Button
            label="Insert"
            icon="pi pi-save"
            loading={loading}
            onClick={handleClickInsert}
          />
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Dialog
      visible={visible}
      style={{ width: "30rem" }}
      header={title}
      modal
      footer={dialogFooter}
      onHide={onHide}
    >
      <div className="col-start-center relative h-100">
        <div className="row-center-center w-100 gap-4 pt-2">
          {/* <span className="font-semibold text-lg">Scan QR</span> */}
          <i className="pi pi-qrcode text-7xl"> </i>
          <InputText
            ref={refInput}
            autoFocus
            className="text-center text-4xl w-[18rem] h-[5rem]"
            value={qrCode}
            onChange={(e) => setQrCode(e.target.value)}
            onKeyDown={handleEnter}
          />
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
});

export default ScanQRDialog;
