import { useContext, useRef, useState } from "react";
import UiContext from "../../../../../store/ui-context";
import { Button } from "primereact/button";
import { format } from "date-fns";
import {
  Fa1,
  FaArrowRightFromBracket,
  FaArrowRightToBracket,
  FaCalendarCheck,
} from "react-icons/fa6";
import ScanQRDialog from "../../../../../dialogs/ScanQRDialog";
import AppointmentDetailDialog from "../../../../../dialogs/AppointmentDetailDialog";

const AppointmentSection = (props) => {
  const qrCodeDialogRef = useRef(null);

  const { sales, width, data = {}, loading, onCheckIn, onCheckOut } = props;
  const { crm_schedule_customer_id, check_in, check_out } = data;

  const [showAppointmentDetailDialog, setShowAppointmentDetailDialog] =
    useState(false);
  const [showQRCodeDialog, setShowQRCodeDialog] = useState(false);
  const [scanInMode, setScanInMode] = useState(false);

  const uiContext = useContext(UiContext);

  const handleClickAppointment = () => {
    if (data.customer_id) {
      if (data.crm_schedule_customer_id && data.crm_schedule_customer_id > 0) {
        setShowAppointmentDetailDialog(true);
      } else {
        uiContext.showErrorMsg("Error", "Customer has no appointment");
      }
    } else {
      uiContext.showErrorMsg("Error", "Please select a customer first");
    }
  };

  const handleClickCheckIn = () => {
    if (data.customer_id) {
      setScanInMode(true);
      setShowQRCodeDialog(true);
    } else {
      uiContext.showErrorMsg("Error", "Please select a customer first");
    }
  };

  const handleClickCheckOut = () => {
    if (data.customer_id) {
      if (!data.check_in) {
        uiContext.showErrorMsg("Error", "Customer must check in first");
      } else {
        setScanInMode(false);
        setShowQRCodeDialog(true);
      }
    } else {
      uiContext.showErrorMsg("Error", "Please select a customer first");
    }
  };

  const handleConfirmScanQRDialog = async (barcode) => {
    let success = false;
    if (scanInMode) {
      success = await onCheckIn(barcode);
    } else {
      success = await onCheckOut(barcode);
    }

    if (success) {
      setShowQRCodeDialog(false);
    } else {
      qrCodeDialogRef.current.clearBarcode();
      qrCodeDialogRef.current.focusInput();
    }
  };

  return (
    <div className={`row-start-center ${width} gap-1`}>
      <ScanQRDialog
        ref={qrCodeDialogRef}
        title={scanInMode ? "Scan Check In" : "Scan Check Out"}
        visible={showQRCodeDialog}
        onHide={() => setShowQRCodeDialog(false)}
        onConfirm={handleConfirmScanQRDialog}
        loading={loading}
        forceable={!scanInMode}
      />

      <AppointmentDetailDialog
        visible={showAppointmentDetailDialog}
        data={data}
        onHide={() => setShowAppointmentDetailDialog(false)}
      />

      <Button
        loading={loading}
        className="max-h-[2rem] gap-2"
        icon={<FaCalendarCheck />}
        label={
          crm_schedule_customer_id && crm_schedule_customer_id > 0
            ? "Has Appt."
            : "No Appt."
        }
        severity={
          crm_schedule_customer_id && crm_schedule_customer_id > 0
            ? "primary"
            : "info"
        }
        onClick={handleClickAppointment}
      />
      <Button
        loading={loading}
        className="max-h-[2rem] gap-3"
        icon={<FaArrowRightToBracket />}
        label={check_in ? format(new Date(check_in), "HH:mm") : "-"}
        severity={check_in ? "primary" : "info"}
        onClick={handleClickCheckIn}
      />
      <Button
        loading={loading}
        className="max-h-[2rem] gap-3"
        icon={<FaArrowRightFromBracket />}
        label={check_out ? format(new Date(check_out), "HH:mm") : "-"}
        severity={check_out ? "primary" : "info"}
        onClick={handleClickCheckOut}
      />
    </div>
  );
};

export default AppointmentSection;
