import {
  FaBlackTie,
  FaBook,
  FaBullseye,
  FaCakeCandles,
  FaCalendarCheck,
  FaCashRegister,
  FaChartBar,
  FaChartSimple,
  FaCreditCard,
  FaDatabase,
  FaDisplay,
  FaDollarSign,
  FaFaceSmile,
  FaGears,
  FaGroupArrowsRotate,
  FaHouse,
  FaLayerGroup,
  FaReceipt,
  FaSackDollar,
  FaSquareCheck,
  FaTags,
  FaWarehouse,
} from "react-icons/fa6";

const MenuFinance = [
  {
    label: "Home",
    to: "",
    icon: <FaHouse />,
  },
  {
    label: "Transaction",
    type: "menu-title",
  },
  {
    label: "Sales",
    to: "transaction/sales",
    icon: <FaReceipt />,
  },
  {
    label: "Sales Journal",
    to: "transaction/salesjournal",
    icon: <FaBook />,
  },
  {
    label: "Appointment",
    to: "transaction/appointment",
    icon: <FaCalendarCheck />,
  },
  {
    label: "Customer Data",
    to: "transaction/customerdata",
    icon: <FaFaceSmile />,
  },
  {
    label: "Price List",
    to: "transaction/price-list",
    icon: <FaDollarSign />,
  },
  {
    label: "Birthday",
    to: "transaction/birthday",
    icon: <FaCakeCandles />,
  },
  {
    label: "Follow Up",
    to: "transaction/follow-up",
    icon: <FaSquareCheck />,
  },
  {
    label: "Stock",
    type: "menu-title",
  },
  {
    label: "Stock Balance",
    to: "stock/stockbalance",
    icon: <FaChartSimple />,
  },
  {
    label: "Stock Movement",
    to: "stock/stockmovement",
    icon: <FaChartSimple />,
  },
  {
    label: "Report",
    type: "menu-title",
  },
  {
    label: "Report",
    to: "report",
    icon: <FaChartBar />,
  },
  {
    label: "Master & Setting",
    type: "menu-title",
  },
  {
    label: "Master Transaksi",
    icon: <FaDatabase />,
    type: "menu-group",
    children: [
      {
        label: "Branch",
        to: "master/branch",
        icon: <FaBlackTie />,
      },
      {
        label: "Warehouse",
        to: "master/warehouse",
        icon: <FaWarehouse />,
      },
      {
        label: "Product Category",
        to: "master/productcategory",
        icon: <FaGroupArrowsRotate />,
      },
      {
        label: "Product Display",
        to: "master/productdisplay",
        icon: <FaDisplay />,
      },
      {
        label: "Product",
        to: "master/product",
        icon: <FaTags />,
      },
      {
        label: "Payment",
        to: "master/payment",
        icon: <FaCashRegister />,
      },
      {
        label: "Voucher",
        to: "master/voucher",
        icon: <FaCreditCard />,
      },
      {
        label: "Expense Group",
        to: "master/expensegroup",
        icon: <FaLayerGroup />,
      },
      {
        label: "Expense",
        to: "master/expense",
        icon: <FaSackDollar />,
      },
      {
        label: "Target",
        to: "master/target",
        icon: <FaBullseye />,
      },
      {
        label: "Discount",
        to: "master/discount",
        icon: <FaTags />,
      },
    ],
  },
  {
    label: "Setting",
    icon: <FaGears />,
    to: "setting/general",
    children: [],
  },
];

export default MenuFinance;
