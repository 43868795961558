import { decryptString, encryptString } from "./EncryptDecryptData";

// output is url friendly because it will be used in the url
const encodeQueryParams = async (queryParamsText) => {
  const encryptedString = encryptString(queryParamsText);
  return encodeURIComponent(encryptedString);
};

const decodeQueryParams = async (queryParamsText) => {
  const encryptedString = decodeURIComponent(queryParamsText);
  return decryptString(encryptedString);
};

export { encodeQueryParams, decodeQueryParams };
