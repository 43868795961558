import { useContext, useEffect, useRef, useState } from "react";
import UiContext from "../../../../../store/ui-context";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import generateDefaultValue from "../../../../../utils/GenerateDefaultValue";
import useMDEditorPageControl from "../../../../../custom_hooks/complex/useMDEditorPageControl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import configs from "../StockTransferConfigs";
import generateTableConfigs from "../../../../../utils/TableConfigGenerator";
import ProductSelectorDialog from "../../../../../dialogs/selectors/ProductSelectorDialog";
import dateTextReformat from "../../../../../utils/DateTextReformat";
import { setLayoutDashboardTitle } from "../../../../../store/ui-slice";

const apiName = configs.master.apiName;
const detailField = configs.detail.detail_field;

const titlePrefix = "Stock Transfer - ";
const titleDateField = "transaction_date";
const titleNumberField = "number";

const apiDetail = "stock/stocktransferdetail";
const tansactionIdField = "stock_transfer_id";

let tableConfigs = generateTableConfigs(configs.detail.columns);

const generateNumbering = (listData, start) => {
  let no = start ?? 1;
  for (const el of listData) el.urutan = no++;
  return listData;
};

let customHandleEdit = undefined;

const generateAddDialog = ({ visible, setVisible, onConfirm }) => {
  return (
    <ProductSelectorDialog
      single={true}
      visible={visible}
      onHide={() => setVisible(false)}
      additionalFilter="product_type:=:2"
      onConfirm={(e, data) => {
        let newDataArr = onConfirm({
          event: e,
          data: data,
          field: "product_id",
          dataField: "data_product",
        });
        if (newDataArr && newDataArr.length >= 1) {
          // customHandleEdit(newDataArr[0]);
          customHandleEdit(newDataArr);
        }
      }}
    />
  );
};

const convertAddDetailData = (detailData, headerValue) => {
  const newDetailData = {
    ...detailData,
  };
  return newDetailData;
};

const useStockTransferEditorPage = (props) => {
  const location = useLocation();
  const currentUrl = location.pathname;

  const [newTableConfigs, setNewTableConfigs] = useState([]);

  const sideMenuIsVisible = useSelector((state) => state.ui.sideMenuIsVisible);
  useState(false);

  const uiContext = useContext(UiContext);
  const dispatch = useDispatch();

  let { id } = useParams();

  const {
    // UIs
    toast,
    elements,
    addDialogSelector,
    // states
    showDetailEditorDialog,
    setShowDetailEditorDialog,
    detailEditorValue,
    setDetailEditorValue,
    detailEditorError,
    setDetailEditorError,
    setHeaderError,
    listData,
    setListData,
    selectedRows,
    headerValue,
    setHeaderValue,
    loading,
    //methods
    generateSelectorDialog,
    handleDelete,
    handleDeleteMultiple,
    handleEdit,
    handleSelectionChange,
    generateSaveBody,
    validator,
    handleReload,
    handleSave,
    handleAdd,
    handleEditorSave,
    //others
    configHeaders,
    requestGet,
    requestGetOne,
    requestPost,
    requestDelete,
  } = useMDEditorPageControl({
    generateDefaultValue,
    configs,
    apiName,
    generateNumbering,
    id,
    detailField,
    generateAddDialog,
    convertAddDetailData,
    manuallyAddDetail: true,
  });

  useEffect(() => {
    setNewTableConfigs(tableConfigs);
  }, []);

  useEffect(() => {
    const title = `${titlePrefix}${
      headerValue[titleNumberField]
    } - ${dateTextReformat(headerValue[titleDateField])}`;
    dispatch(setLayoutDashboardTitle({ title }));
  }, [headerValue]);

  const convertSaveBody = () => {
    const newBody = generateSaveBody();
    newBody["branch_id"] = 1;
    return newBody;
  };

  const validation = () => {
    return validator({
      configs: configHeaders,
      value: headerValue,
      onError: (error) => setHeaderError(error),
      isEmpty: "",
    });
  };

  customHandleEdit = async (rows) => {
    const newRows = rows;
    if (rows && Array.isArray(rows)) {
      if (rows.length <= 1) {
        handleEdit(newRows[0]);
      } else {
        let resultMessage = "";
        const listNewData = [];
        for (const row of newRows) {
          await handleSaveDetail(undefined, row, ({ data, message }) => {
            listNewData.push(data);
            resultMessage = message;
          });
        }
        if (listNewData.length > 0) {
          setListData([...listNewData, ...listData]);
          uiContext.showSuccessMsg("Success", resultMessage);
        }
      }
    } else if (rows) {
      handleEdit(newRows);
    }
  };

  const handleSaveDetail = async (event, newValue) => {
    newValue[tansactionIdField] = headerValue.id;
    newValue["conversion"] = 1;

    if (newValue.id < 1) {
      delete newValue.id;
    }

    await requestPost({
      fullUrl: `api/${apiDetail}/save`,
      body: newValue,
      isForm: false,
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
      onSuccess: ({ data, message }) => {
        uiContext.showSuccessMsg("Success", message);
        setShowDetailEditorDialog(false);
        reloadDetail(data.id);
      },
    });
  };

  const handleDeleteDetail = async (row) => {
    const accept = async () => {
      await requestDelete({
        fullUrl: `api/${apiDetail}/delete`,
        ids: [row.id],
        onError: ({ message, data }) => {
          uiContext.showErrorMsg("Error", message);
        },
        onSuccess: ({ data, message }) => {
          uiContext.showSuccessMsg("Success", message);
          handleReload();
          // const newListDataTemporaries = listDataTemporaries.filter(
          //   (el) => el.id !== row.id
          // );
          // setListDataTemporaries(newListDataTemporaries);
        },
      });
    };

    const reject = () => {};

    confirmDialog({
      message: `Menghapus data, lanjutkan?`,
      header: "Konfirmasi ",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      acceptLabel: "Konfirmasi",
      rejectLabel: "Tidak",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const handleClose = () => {
    window.close();
  };

  const reloadDetail = (id) => {
    if (id > 0) {
      requestGet({
        fullUrl: `api/${apiDetail}/data`,
        params: {
          filter: `id:=:${id}`,
          with_product: 1,
        },
        onSuccess: ({ message, data }) => {
          if (data && data.length > 0) {
            const dataSingle = data[0];
            // find index of an element in listData which id is equal to dataSingle.id
            // if found, replace the element with dataSingle
            // if not found, add dataSingle to listData on the first index
            const index = listData.findIndex((el) => el.id === dataSingle.id);
            if (index > -1) {
              listData[index] = dataSingle;
              setListData([...listData]);
            } else {
              setListData([dataSingle, ...listData]);
            }
          }
        },
        onError: ({ message, data }) => {
          uiContext.showErrorMsg("Error", message);
        },
      });
    } else {
      const newListData = listData.filter((el) => el.id !== id);
      setListData(newListData);
    }
  };

  return {
    toast,
    loading,
    addDialogSelector,
    detailEditorValue,
    setDetailEditorValue,
    detailEditorError,
    setDetailEditorError,
    showDetailEditorDialog,
    setShowDetailEditorDialog,
    handleSaveDetail,
    elements,
    handleAdd,
    headerValue,
    validation,
    convertSaveBody,
    handleSave,
    handleClose,
    handleReload,
    selectedRows,
    handleSelectionChange,
    listData,
    customHandleEdit,
    sideMenuIsVisible,
    tableConfigs: newTableConfigs,
    handleDeleteDetail,
    generateSelectorDialog,
  };
};

export default useStockTransferEditorPage;
