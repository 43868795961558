import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useState } from "react";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import generateTableConfigs from "../../../../utils/TableConfigGenerator";
import { FaUser, FaUserDoctor, FaUserNurse } from "react-icons/fa6";

const firstDateOfMonth = new Date();
firstDateOfMonth.setDate(1);
firstDateOfMonth.setHours(0, 0, 0, 0);

const today = new Date();

const customColumns = [
  {
    field: "data_sales.number",
    component: (rowData) => {
      if (rowData && rowData.data_sales) {
        return (
          <div className="col-start-start leading-loose">
            <span>{rowData.data_sales.number}</span>
            <span className="text-xs font-bold text-slate-400">
              {rowData.data_sales.transaction_date}
            </span>
          </div>
        );
      } else {
        return "";
      }
    },
  },
  {
    field: "data_product.name",

    component: (rowData) => {
      const {
        data_product,
        data_doctor,
        data_nurse_1,
        data_nurse_2,
        data_nurse_3,
        data_therapist_1,
        data_therapist_2,
        data_therapist_3,
      } = rowData;
      if (rowData && rowData.data_product) {
        return (
          <div className="col-start-start leading-snug">
            <span>{data_product.name}</span>
            {(data_doctor ||
              data_nurse_1 ||
              data_nurse_2 ||
              data_therapist_1 ||
              data_therapist_2) && (
              <div className="row-start-center gap-1 py-1 flex-wrap">
                {/* Doctor section */}
                {data_doctor && (
                  <div className="row-start-center gap-2 pr-2">
                    <FaUserDoctor className="text-slate-500 text-sm" />
                    <span className="text-xs text-slate-500 pt-1">
                      {data_doctor ? data_doctor.name : "-"}
                    </span>
                  </div>
                )}
                {/* Nurse section */}
                {(data_nurse_1 || data_nurse_2) && (
                  <div className="row-start-center gap-2 pr-2">
                    <FaUserNurse className="text-slate-500 text-sm" />
                    <span className="text-xs text-slate-500  pt-1">
                      {data_nurse_1.name}
                    </span>
                    {data_nurse_2 && (
                      <span className="text-xs text-slate-500 pt-1">
                        {data_nurse_2.name}
                      </span>
                    )}
                  </div>
                )}
                {/* Therapist section */}
                {(data_therapist_1 || data_therapist_2) && (
                  <div className="row-start-center gap-2">
                    <FaUser className="text-slate-500 text-sm" />
                    <span className="text-xs text-slate-500 pt-1">
                      {data_therapist_1.name}
                    </span>
                    {data_therapist_2 && (
                      <span className="text-xs text-slate-500 pt-1">
                        {data_therapist_2.name}
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      } else {
        return "";
      }
    },
  },
];

const configs = [
  // add_configs
  {
    name: "Sales Invoice",
    field: "data_sales.number",
    type: "text_plain",
    table: true,
  },
  {
    name: "Product",
    field: "data_product.name",
    type: "text_plain",
    table: true,
    filter: true,
  },
  {
    name: "Price",
    field: "price",
    type: "number_plain",
    table: true,
    filter: true,
  },
  {
    name: "Qty",
    field: "quantity",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Grand Total",
    field: "grand_total",
    type: "number_plain",
    table: true,
    filter: true,
  },
];

const tableConfigs = generateTableConfigs(configs);

const SalesHistory = (props) => {
  const { onLoad, data, loading } = props;

  const [selectedDate, setSelectedDate] = useState([firstDateOfMonth, today]);

  const handleOnClickLoad = () => {
    let fromDate = selectedDate.length > 0 ? selectedDate[0] : null;
    let toDate = selectedDate.length > 1 ? selectedDate[1] : null;

    if (fromDate && toDate && onLoad) onLoad({ fromDate, toDate });
  };

  return (
    <div className="col-start-start gap-4">
      <div className="row-start-end gap-2 w-full">
        <div className="col-start-start">
          <span className="font-semibold text-sm text-zinc-400">
            Transaction Date
          </span>
          <Calendar
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.value)}
            selectionMode="range"
            readOnlyInput
            showIcon
            showButtonBar
          />
        </div>
        <Button
          className="w-[6rem] min-h-[2.6rem]"
          icon="pi pi-refresh"
          label="Load"
          onClick={handleOnClickLoad}
        />
      </div>
      <div className={`col-center-center w-100 gap-2`}>
        <TableAutoComponent
          singleMode={false}
          selection={[]}
          onSelectionChange={() => {}}
          configs={tableConfigs}
          loading={loading}
          value={data}
          customColumns={customColumns}
          // auto pagination
          rows={10}
          paginator={true}
          rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
        />
      </div>
    </div>
  );
};

export default SalesHistory;
