import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useState } from "react";
import { format } from "date-fns";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import generateTableConfigs from "../../../../utils/TableConfigGenerator";

const firstDateOfMonth = new Date();
firstDateOfMonth.setDate(1);
firstDateOfMonth.setHours(0, 0, 0, 0);

const today = new Date();

const customColumns = [
  {
    field: "created_at",
    component: (rowData) => {
      if (rowData) {
        const dateText = rowData.created_at;
        return format(new Date(dateText), "dd/MM/yyyy");
      } else {
        return "";
      }
    },
  },
];

const configs = [
  // add_configs
  {
    name: "Date",
    field: "transaction_date",
    type: "date_date",
    table: true,
  },
  {
    name: "Check In",
    field: "check_in",
    type: "date_time",
    format: "HH:mm",
    table: true,
  },
  {
    name: "Check Out",
    field: "check_out",
    type: "date_date",
    format: "HH:mm",
    table: true,
  },
  {
    name: "Sales Invoice",
    field: "sales_number",
    type: "text_plain",
    table: true,
  },
  {
    name: "With Appt.",
    field: "with_appointment",
    type: "text_plain",
    table: true,
    filter: true,
  },
  {
    name: "Duration (H:M)",
    field: "duration",
    align: "right",
    type: "text_plain",
    table: true,
  },
  // {
  //   name: "Usage Date",
  //   field: "usage_date",
  //   type: "date_date",
  //   table: true,
  // },
];

const tableConfigs = generateTableConfigs(configs);

const CheckInOutHistory = (props) => {
  const { onLoad, data, loading } = props;

  const [selectedDate, setSelectedDate] = useState([firstDateOfMonth, today]);

  const handleOnClickLoad = (loadAll) => {
    let fromDate = selectedDate.length > 0 ? selectedDate[0] : null;
    let toDate = selectedDate.length > 1 ? selectedDate[1] : null;

    if (fromDate && toDate && onLoad) {
      if (loadAll) onLoad({ fromDate: null, toDate: null });
      else onLoad({ fromDate, toDate });
    }
  };

  return (
    <div className="col-start-start gap-4">
      <div className="row-start-end gap-2 w-full">
        <div className="col-start-start">
          <span className="font-semibold text-sm text-zinc-400">
            Transaction Date
          </span>
          <Calendar
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.value)}
            selectionMode="range"
            readOnlyInput
            showIcon
            showButtonBar
          />
        </div>
        <Button
          className="w-[6rem] min-h-[2.6rem]"
          icon="pi pi-refresh"
          label="Load"
          onClick={handleOnClickLoad}
        />
        <Button
          className="w-[8rem] min-h-[2.6rem]"
          icon="pi pi-refresh"
          label="Load All"
          onClick={() => handleOnClickLoad(true)}
        />
      </div>
      <div className={`col-center-center w-100 gap-2`}>
        <TableAutoComponent
          singleMode={false}
          selection={[]}
          onSelectionChange={() => {}}
          configs={tableConfigs}
          loading={loading}
          value={data}
          customColumns={customColumns}
          // auto pagination
          rows={10}
          paginator={true}
          rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
        />
      </div>
    </div>
  );
};

export default CheckInOutHistory;
