import ListAppointmentItem from "./ListAppointmentItem";

const bgColors = [
  "bg-green-300",
  "bg-yellow-300",
  "bg-purple-300",
  "bg-blue-300",
  "bg-red-300",
  "bg-pink-300",
  "bg-indigo-300",
  "bg-cyan-300",
  "bg-rose-300",
  "bg-emerald-300",
];

const textColors = [
  "text-green-500",
  "text-yellow-500",
  "text-purple-500",
  "text-blue-500",
  "text-red-500",
  "text-pink-500",
  "text-indigo-500",
  "text-cyan-500",
  "text-rose-500",
  "text-emerald-500",
];

const ListAppointment = (props) => {
  const { events, onClick } = props;
  return (
    <div className="col-start-start gap-4 w-full">
      {" "}
      {events &&
        events.map((event, index) => {
          return (
            <ListAppointmentItem
              key={index}
              event={event}
              backgroundColor={bgColors[index % bgColors.length]}
              textColor={textColors[index % textColors.length]}
              onClick={onClick}
            />
          );
        })}
    </div>
  );
};

export default ListAppointment;
