import React from "react";
import TableAutoComponent from "../../components/complex/crud/TableAutoComponent";
import FilterComponent from "../../components/complex/crud/FilterComponent";
import generateFilterConfigs from "../../utils/FilterConfigGenerator";
import generateTableConfigs from "../../utils/TableConfigGenerator";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";

import useSelectorDialogControl from "../../custom_hooks/complex/useSelectorDialogControl";

const title = "CRM Option";
const apiName = "crm/crmoption";
const orderBy = "id";
const orderMethod = "ASC";
const additionalParams = {};

const customColumns = [];

const configs = [
  // add_configs
  {
    name: "Name",
    field: "name",
    type: "text_plain",
    table: true,
    filter: true,
    editor: true,
    object_name: "",
    object_field: "",
  },
  // {
  //   name: "Duration",
  //   field: "duration",
  //   type: "number_plain",
  //   editor: true,
  //   align: "right",
  //   object_name: "",
  //   object_field: "",
  //   table: true,
  // },
];

const filterConfigs = generateFilterConfigs(configs);
const tableConfigs = generateTableConfigs(configs);

const CRMOptionSelectorDialog = (props) => {
  const single = props.single ?? true;

  const {
    toast,
    listData,
    paging,
    loading,
    setListData,
    selectedRows,
    setSelectedRows,
    refineValue,
    reloadListData,
    handleOnFilter,
    handleOnPage,
    handleSelectionChange,
  } = useSelectorDialogControl({
    apiName,
    orderBy,
    orderMethod,
    additionalParams,
    visible: props.visible,
  });

  const dialogFooter = (
    <div className="col-center-center w-100">
      <Paginator
        first={paging.take * (paging.page - 1)}
        rows={paging.take}
        totalRecords={paging.total}
        rowsPerPageOptions={[5, 8, 10, 20, 50]}
        onPageChange={handleOnPage}
      />
      <div className="row-end-end w-100">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
        <Button
          label="Pilih"
          icon="pi pi-check"
          onClick={(e) => {
            const refinedValue = refineValue(selectedRows);
            props.onConfirm(e, refinedValue);
          }}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[23rem] sm:w-[44rem] min-h-[40rem]`}
      footer={dialogFooter}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className={`col-start-start w-100 gap-3`}>
        {filterConfigs && filterConfigs.length > 0 && (
          <FilterComponent onFilter={handleOnFilter} configs={filterConfigs} />
        )}
        <div className={`col-center-center w-100 gap-2`}>
          <TableAutoComponent
            selectable
            singleMode={single}
            selection={selectedRows}
            onSelectionChange={handleSelectionChange}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            customColumns={customColumns}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CRMOptionSelectorDialog;
