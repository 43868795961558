import { useState } from "react";
import AddItemDiscountDialog from "../../../../../dialogs/AddItemDiscountDialog";
import ListSalesDetailSingle from "./ListSalesDetailSingle";
import DoctorSelectorDialog from "../../../../../dialogs/selectors/DoctorSelectorDialog";
import TherapistSelectorDialog from "../../../../../dialogs/selectors/TherapistSelectorDialog";
import NurseSelectorDialog from "../../../../../dialogs/selectors/NurseSelectorDialog";
import { th } from "date-fns/locale";
import { confirmDialog } from "primereact/confirmdialog";

const ListSalesDetailSection = (props) => {
  const {
    width,
    data,
    onDelete,
    onChangeQuantity,
    loading,
    onChangeDiscount,
    onChangeDoctor,
    onChangeTherapist,
    onChangeNurse,
  } = props;
  const data_sales_items = data;
  const [visibleItemDiscountDialog, setVisibleItemDiscountDialog] =
    useState(false);
  const [visibleDoctorDialog, setVisibleDoctorDialog] = useState(false);
  const [visibleTherapistDialog, setVisibleTherapistDialog] = useState(false);
  const [visibleNurseDialog, setVisibleNurseDialog] = useState(false);
  const [selectedSalesItem, setSelectedSalesItem] = useState(null);

  const handleClickDiscount = (salesItem) => {
    const { discount_value, discount_total } = salesItem;
    if (discount_value === 0 && discount_total === 0) {
      setSelectedSalesItem(salesItem);
      setVisibleItemDiscountDialog(true);
    } else {
      confirmDialog({
        message: "You are about to remove a discount, continue?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          onChangeDiscount({
            discount: null,
            salesItem: salesItem,
          });
        },
      });
    }
  };

  const handleClickDoctor = (salesItem) => {
    setSelectedSalesItem(salesItem);
    setVisibleDoctorDialog(true);
  };

  const handleClickTherapist = (salesItem) => {
    setSelectedSalesItem(salesItem);
    setVisibleTherapistDialog(true);
  };

  const handleClickNurse = (salesItem) => {
    setSelectedSalesItem(salesItem);
    setVisibleNurseDialog(true);
  };

  const handleClickDelete = (salesItemId) => {
    onDelete(salesItemId);
  };

  const handleChangeQuantity = ({ quantity, row }) => {
    onChangeQuantity({ quantity, row });
  };

  return (
    <>
      <AddItemDiscountDialog
        visible={visibleItemDiscountDialog}
        onHide={(e) => setVisibleItemDiscountDialog(false)}
        onConfirm={(discount) => {
          onChangeDiscount({ discount, salesItem: selectedSalesItem });
          setVisibleItemDiscountDialog(false);
        }}
      />
      <DoctorSelectorDialog
        visible={visibleDoctorDialog}
        onHide={(e) => setVisibleDoctorDialog(false)}
        onConfirm={(e, refinedValue) => {
          onChangeDoctor({
            doctor: refinedValue,
            salesItem: selectedSalesItem,
          });
          setVisibleDoctorDialog(false);
        }}
      />
      <TherapistSelectorDialog
        single={false}
        visible={visibleTherapistDialog}
        onHide={(e) => setVisibleTherapistDialog(false)}
        onConfirm={(e, refinedValue) => {
          onChangeTherapist({
            therapist: refinedValue,
            salesItem: selectedSalesItem,
          });
          setVisibleTherapistDialog(false);
        }}
      />
      <NurseSelectorDialog
        single={false}
        visible={visibleNurseDialog}
        onHide={(e) => setVisibleNurseDialog(false)}
        onConfirm={(e, refinedValue) => {
          onChangeNurse({ nurse: refinedValue, salesItem: selectedSalesItem });
          setVisibleNurseDialog(false);
        }}
      />
      <div
        className={`col-start-center ${width} gap-2 h-full py-1`}
        style={{
          minHeight: "calc(100vh - var(--height-nav-bar) - 30rem - 3.5rem)",
        }}
      >
        {data &&
          data_sales_items &&
          data_sales_items.length > 0 &&
          data_sales_items.map((salesItem, index) => {
            return (
              <ListSalesDetailSingle
                key={salesItem.id}
                {...salesItem}
                loading={loading}
                onClickDiscount={handleClickDiscount}
                onClickDoctor={handleClickDoctor}
                onClickNurse={handleClickNurse}
                onClickTherapist={handleClickTherapist}
                onClickDelete={handleClickDelete}
                onChangeQuantity={(newQty) =>
                  handleChangeQuantity({ quantity: newQty, row: salesItem })
                }
              />
            );
          })}
      </div>
    </>
  );
};

export default ListSalesDetailSection;
