import { Button } from "primereact/button";
import { useContext, useState } from "react";
import RedeemPointDialog from "../../../../../dialogs/RedeemPointDialog";
import AddCustomerPackageDialog from "../../../../../dialogs/AddCustomerPackageDialog";
import UiContext from "../../../../../store/ui-context";

const InsertSection = (props) => {
  const { loading, onAddCustomerPackage, onAddRedeem, data } = props;
  const { width } = props.data;

  const uiContext = useContext(UiContext);
  const [showCustomerPackageDialog, setShowCustomerPackageDialog] =
    useState(false);
  const [showRedeemPointDialog, setShowRedeemPointDialog] = useState(false);

  const handleAddCustomerPackage = () => {
    if (data.customer_id) {
      setShowCustomerPackageDialog(true);
    } else {
      uiContext.showErrorMsg("Error", "Please select a customer first");
    }
  };

  const handleRedeemPoint = () => {
    if (data.customer_id) {
      setShowRedeemPointDialog(true);
    } else {
      uiContext.showErrorMsg("Error", "Please select a customer first");
    }
  };

  return (
    <div className={`row-center-center ${width} gap-1`}>
      <AddCustomerPackageDialog
        single={false}
        visible={showCustomerPackageDialog}
        data={props.data.data_customer}
        onHide={() => setShowCustomerPackageDialog(false)}
        onConfirm={(e, refinedValue) => {
          onAddCustomerPackage(refinedValue);
          setShowCustomerPackageDialog(false);
        }}
      />
      <RedeemPointDialog
        visible={showRedeemPointDialog}
        onHide={() => setShowRedeemPointDialog(false)}
        data={data && data.data_customer ? data.data_customer : null}
        onConfirm={(e, refinedValue) => {
          onAddRedeem(refinedValue);
          setShowRedeemPointDialog(false);
        }}
      />
      <Button
        className="max-h-[2rem]"
        icon="pi pi-plus"
        label="Package"
        loading={loading}
        onClick={(e) => {
          e.preventDefault();
          handleAddCustomerPackage();
        }}
      />
      <Button
        className="max-h-[2rem]"
        icon="pi pi-plus"
        label="Redeem"
        loading={loading}
        onClick={(e) => {
          e.preventDefault();
          handleRedeemPoint();
        }}
      />
    </div>
  );
};

export default InsertSection;
