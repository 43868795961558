import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import { format as dateFnsFormat, parse } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import UiContext from "../../../../store/ui-context";
import WarehouseSelectorDialog from "../../../../dialogs/selectors/WarehouseSelectorDialog";
import { useDispatch } from "react-redux";
import { Paginator } from "primereact/paginator";
import { setLayoutDashboardTitle } from "../../../../store/ui-slice";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
// import BirthdayNoteDialog from "./BirthdayNoteDialog";

const title = "Price List";

const PriceListPage = () => {
  const uiContext = useContext(UiContext);
  const toast = useRef();
  const dispatch = useDispatch();

  const [paging, setPaging] = useState({
    page: 1,
    take: 20,
    filter: "",
  });

  const refFilterCategory = useRef(null);
  const refFilterDisplay = useRef(null);
  const refFilterName = useRef(null);

  const [listData, setListData] = useState([]);

  const { requestGet, requestPost, loading } = useAPIRequest();

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  const generateFilter = () => {
    let filter = "";

    const filterCategory = refFilterCategory.current.value.trim();
    if (filterCategory && filterCategory.length > 0) {
      if (filter.length > 0) filter += ";";
      filter += `data_product_category.name:contains:%${filterCategory}%`;
    }

    const filterDisplay = refFilterDisplay.current.value.trim();
    if (filterDisplay && filterDisplay.length > 0) {
      if (filter.length > 0) filter += ";";
      filter += `data_product_display.name:contains:%${filterDisplay}%`;
    }

    const filterName = refFilterName.current.value.trim();
    if (filterName && filterName.length > 0) {
      if (filter.length > 0) filter += ";";
      filter += `name:contains:%${filterName}%`;
    }

    if (filter.length > 0) filter += ";";
    filter += `name:!=:.`;

    return filter;
  };

  const reloadData = async ({ page, take, focusCompRef }) => {
    if (loading) return;

    setListData([]);

    const filter = generateFilter();

    const params = {
      page: page,
      take: take,
      filter,
      with_product_category: 1,
      with_product_display: 1,
      order: "name",
      order_method: "ASC",
    };

    return requestGet({
      fullUrl: "api/masterdata/product/data",
      params,
      onSuccess: ({ pagination, message, data }) => {
        const page = pagination["page"];
        const take = pagination["take"];

        setPaging({
          ...paging,
          total: pagination["total"],
          page,
          take,
          filter,
        });

        const newData = data
          .filter((el) => el.name.trim() !== ".")
          .map((el, index) => {
            return { ...el, no: (page - 1) * take + index + 1 };
          });

        setListData(newData);

        if (focusCompRef) {
          setTimeout(() => {
            focusCompRef.current.focus();
          }, 300);
        }
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });
  };

  const handleReload = () => {
    reloadData({
      page: paging.page,
      take: paging.take,
    });
  };

  //   const handleOnPage = (e) => {
  //     const newPage = e.first / paging.take + 1;
  //     const newTake = e.rows;

  //     setPaging({ ...paging, take: newTake, page: newPage });
  //     reloadData({ page: newPage, take: newTake });
  //   };

  const handleEnterFilter = async (e, focusCompRef) => {
    const key = e.key;
    if (key === "Enter") {
      await reloadData({
        page: paging.page,
        take: paging.take,
        focusCompRef,
      });
    }
  };

  const priceBodyTemplate = (row) => {
    const { price } = row;
    return (
      <div className="row-end-center gap-0 ">
        {price.toLocaleString("id-ID")}
      </div>
    );
  };

  const handleOnPage = (e) => {
    const newPage = e.first / paging.take + 1;
    const newTake = e.rows;

    setPaging({ ...paging, take: newTake, page: newPage });
    reloadData({ page: newPage, take: newTake, filter: paging.filter });
  };

  const handleClearFilter = (e) => {
    refFilterCategory.current.value = "";
    refFilterDisplay.current.value = "";
    refFilterName.current.value = "";
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <Toast ref={toast} />
      <div></div>
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20`}
      >
        <div className="row-between-center gap-4">
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <i className="pi pi-search"></i>
            </span>
            <InputText
              ref={refFilterCategory}
              disabled={loading}
              className="w-[12rem]"
              placeholder="Find Category"
              onKeyDown={(e) => handleEnterFilter(e, refFilterCategory)}
            />
          </div>
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <i className="pi pi-search"></i>
            </span>
            <InputText
              ref={refFilterDisplay}
              disabled={loading}
              className="w-[12rem]"
              placeholder="Find Display"
              onKeyDown={(e) => handleEnterFilter(e, refFilterDisplay)}
            />
          </div>
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <i className="pi pi-search"></i>
            </span>
            <InputText
              ref={refFilterName}
              disabled={loading}
              className="w-[12rem]"
              placeholder="Find Product / Service"
              onKeyDown={(e) => handleEnterFilter(e, refFilterName)}
            />
          </div>
          <Button
            icon="pi pi-eraser"
            size="small"
            loading={loading}
            outlined
            onClick={handleClearFilter}
          />
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-file-excel"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-print"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          />
        </div>
      </div>

      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <DataTable
            className="w-full"
            value={listData}
            showGridlines
            stripedRows
            cellClassName="!py-2"
          >
            <Column className="w-[4rem]" field="no" header="No"></Column>
            <Column
              field="data_product_category.name"
              header="Category"
            ></Column>
            <Column field="data_product_display.name" header="Display"></Column>
            <Column field="name" header="Product / Service Name"></Column>
            <Column
              align={"right"}
              field="price"
              header="Price"
              body={priceBodyTemplate}
            ></Column>
          </DataTable>
          <Paginator
            first={paging.take * (paging.page - 1)}
            rows={paging.take}
            totalRecords={paging.total}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageChange={handleOnPage}
          />
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default PriceListPage;
