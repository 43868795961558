import React from "react";
import { format } from "date-fns";
import { Image } from "primereact/image";
import {
  FaAt,
  FaCakeCandles,
  FaCoins,
  FaPhone,
  FaRoad,
  FaStar,
  FaUserTie,
} from "react-icons/fa6";
import { Divider } from "primereact/divider";

const ProfileData = (props) => {
  const { data } = props;

  const CustomerSingleData = (props) => {
    const { icon, prompt, value, noSpace } = props;

    const newIcon = React.cloneElement(icon, {
      className: "text-sm text-slate-400",
    });

    return (
      <div className={`row-between-center gap-3 ${!noSpace ? "w-full" : ""}`}>
        <div className="row-end-center gap-1">
          {newIcon}{" "}
          <span className="font-semibold text-slate-400">{prompt}</span>
        </div>
        <span className="font-semibold text-slate-500">{value}</span>
      </div>
    );
  };

  return (
    <div className="row-start-start w-full gap-5">
      {data && data.code && <Image src={data.image} alt="Image" width="150" />}
      <div className="col-start-start w-full gap-2">
        <div className="row-start-start w-full gap-4">
          <div className="col-start-start w-[14rem] gap-2">
            <CustomerSingleData
              icon={<FaStar />}
              prompt="Level"
              value={data ? data.level : "-"}
            />
            <CustomerSingleData
              icon={<FaUserTie />}
              prompt="BC"
              value={data && data.data_bc ? data.data_bc.name : "-"}
            />
            <CustomerSingleData
              icon={<FaCoins />}
              prompt="Points"
              value={data ? data.point.toLocaleString("id-ID") : "-"}
            />
            <CustomerSingleData
              icon={<FaCakeCandles />}
              prompt="Birthday"
              value={
                data && data.birth_date
                  ? format(new Date(data.birth_date), "dd/MM/yyyy")
                  : "-"
              }
            />
          </div>
          <Divider className="!m-0 !mx-3 h-[7.5rem]" layout="vertical" />
          <div className="col-start-start w-[14rem] gap-2">
            <CustomerSingleData
              icon={<FaPhone />}
              prompt="Phone 1"
              value={data ? data.phone_1 : "-"}
            />
            <CustomerSingleData
              icon={<FaPhone />}
              prompt="Phone 2"
              value={data ? data.phone_2 : "-"}
            />
            <CustomerSingleData
              icon={<FaAt />}
              prompt="Email"
              value={data ? data.email : "-"}
            />
          </div>
        </div>
        <CustomerSingleData
          icon={<FaRoad />}
          noSpace
          prompt="Address"
          value={data ? data.address : "-"}
        />
      </div>
    </div>
  );
};
export default ProfileData;
