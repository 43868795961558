import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import configs from "./StockInConfigs";
import generateEditorConfigs from "../../../../utils/EditorConfigGenerator";
import useMDEditorDialogControl from "../../../../custom_hooks/complex/useMDEditorDialogControl";
import ProductSelectorDialog from "../../../../dialogs/selectors/ProductSelectorDialog";

const title = "Edit Quantity";
const configEditors = generateEditorConfigs(configs.detail.columns);

const StockInDetailEditorDialog = (props) => {
  const { value, setValue, error, setError, onHide, visible, onSave } = props;

  const { elements, generateSelectorDialog, validator } =
    useMDEditorDialogControl({
      configs,
      value,
      setValue,
      error,
    });

  const convertValue = () => {
    const newValue = { ...props.value };
    return newValue;
  };

  const validation = () => {
    return validator({
      configs: configEditors,
      value: value,
      onError: (error) => setError(error),
      isEmpty: "",
    });
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const valid = validation();
    if (valid) {
      const newValue = convertValue();
      onSave(event, newValue);
    }
  };

  generateSelectorDialog({
    field: "product_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <ProductSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          additionalFilter="product_type:=:2"
          onConfirm={onConfirm}
        />
      );
    },
  });

  const dialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={onHide} />
      <Button label="Save" icon="pi pi-check" onClick={handleSave} />
    </React.Fragment>
  );

  return (
    <Dialog
      visible={visible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={title}
      modal
      className={`p-fluid`}
      footer={dialogFooter}
      onHide={onHide}
    >
      <div className="col-start-start w-100 relative h-100 gap-3">
        {elements.product_id}
        <div className="row-start-start">{elements.quantity}</div>
      </div>
    </Dialog>
  );
};

export default StockInDetailEditorDialog;
