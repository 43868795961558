import { format } from "date-fns";
import { FaUserDoctor, FaUserNurse, FaUserTie } from "react-icons/fa6";
import { motion } from "framer-motion";

const shadowTailWind = "shadow-[0px_0px_4px_0px_#00000024]";

const ListAppointmentItem = (props) => {
  const { event, backgroundColor, textColor, onClick } = props;
  const data = event.data;

  const isPast = event.date < new Date().setHours(0, 0, 0, 0);
  const animate = !event.data.confirmation_bc_date ? true : false;
  const stale = !event.data.confirmation_bc_date ? true : false;

  return (
    <motion.div
      className={`row-start-start ${
        isPast ? " bg-slate-100 " : shadowTailWind
      } ${
        stale ? " !bg-slate-50 " : ""
      } w-full gap-2 cursor-pointer active:translate-y-0.5 active:bg-slate-50 !items-stretch `}
      animate={
        animate
          ? {
              rotate: [0, -2, 2, 0],
              translateY: [0, -2, 0, 0],
            }
          : undefined
      }
      transition={
        animate
          ? {
              duration: 1,
              ease: "easeInOut",
              times: [0, 0.33, 0.677, 1],
              repeat: Infinity,
              repeatDelay: 0,
            }
          : undefined
      }
      onClick={() => {
        if (onClick) onClick(event);
      }}
    >
      <div
        className={` w-[3px] ${
          isPast || stale ? "bg-slate-300" : backgroundColor
        }`}
      />
      <div className={`col-center-start pt-1 w-full`}>
        <div className={`col-start-start`}>
          <span
            className={`text-sm ${
              isPast || stale ? "text-slate-400" : textColor
            } font-semibold`}
          >
            {data && data.data_customer
              ? `${data.data_customer ? data.data_customer.name : "-"} ( ${
                  data.data_customer ? data.data_customer.code : "-"
                } )`
              : event.title}
          </span>
          <span className="text-sm text-slate-400">
            {format(new Date(event.date), "hh:mm aa")}
            {data.edit_by ? `, by ${data.edit_by}` : ""}
          </span>
          {/* BC */}
          {data && data.data_bc && (
            <div className="row-start-center">
              <div className="w-[1.3rem]">
                <FaUserTie className="text-slate-400 text-sm" />
              </div>
              <span className="text-sm text-slate-400">
                {data.data_bc ? data.data_bc.name : "-"}
              </span>
            </div>
          )}
        </div>

        {data && (
          <div className="col-start-start pb-2 pt-3 gap-4 w-full">
            {data.description && (
              <span className="text-sm text-slate-500">{data.description}</span>
            )}
            {/* crm options */}
            {data.data_crm_options && data.data_crm_options.length > 0 && (
              <div className="col-start-start w-full gap-1">
                <div className="row-start-center gap-1 w-full pr-1">
                  <div className="bg-slate-300 w-[0.5rem] h-[2px]" />
                  <span className="text-sm font-semibold text-slate-400">
                    {" "}
                    Customer Request{" "}
                  </span>
                  <div className="grow-1 bg-slate-300 h-[2px]" />
                </div>
                <div className="col-start-start gap-1 pl-2">
                  {data.data_crm_options.map((option, index) => (
                    <div key={index} className="row-start-center gap-2 w-full">
                      <i className="pi pi-circle-fill text-[0.3rem] text-slate-500" />
                      <span className="text-sm text-slate-500">
                        {option.name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* bc recommendation */}
            {data.data_products && data.data_products.length > 0 && (
              <div className="col-start-start w-full gap-1">
                <div className="row-start-center gap-1 w-full pr-1">
                  <div className="bg-slate-300 w-[0.5rem] h-[2px]" />
                  <span className="text-sm font-semibold text-slate-400">
                    BC Recommendation
                  </span>
                  <div className="grow-1 bg-slate-300 h-[2px]" />
                </div>
                <div className="col-start-start gap-1 pl-2">
                  {data.data_products.map((option, index) => (
                    <div key={index} className="row-start-center gap-2 w-full">
                      <i className="pi pi-circle-fill text-[0.3rem] text-slate-500" />
                      <span className="text-sm text-slate-500">
                        {option.data_product.name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* treatment by */}
            <div className="col-start-start w-full gap-1">
              <div className="row-start-center gap-1 w-full pr-1">
                <div className="bg-slate-300 w-[0.5rem] h-[2px]" />
                <span className="text-sm font-semibold text-slate-400">
                  Treatment By
                </span>
                <div className="grow-1 bg-slate-300 h-[2px]" />
              </div>
              <div className="col-start-start gap-1 pl-1">
                {/* doctor */}
                {data.data_doctor && (
                  <div className="row-start-center">
                    <div className="w-[1.3rem]">
                      <FaUserDoctor className="text-slate-500 text-sm" />
                    </div>
                    <span className="text-sm text-slate-500">
                      {data.data_doctor ? data.data_doctor.name : "-"}
                    </span>
                  </div>
                )}
                {/* nurse */}
                {(data.data_therapist_1 ||
                  data.data_therapist_2 ||
                  data.data_therapist_3) && (
                  <div className="row-start-center">
                    <div className="w-[1.3rem]">
                      <FaUserNurse className="text-slate-500 text-sm" />
                    </div>
                    <div className="row-start-center gap-2">
                      <span className="text-sm text-slate-500">
                        {data.data_nurse_1 ? data.data_nurse_1.name : "-"}
                      </span>
                      {data.data_nurse_2 && (
                        <span className="text-sm text-slate-500">
                          {data.data_nurse_2.name}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {/* therapist */}
                {(data.data_therapist_1 ||
                  data.data_nurse_2 ||
                  data.data_nurse_3) && (
                  <div className="row-start-center">
                    <div className="w-[1.3rem]">
                      <FaUserDoctor className="text-slate-500 text-sm" />
                    </div>
                    <div className="row-start-center gap-2">
                      <span className="text-sm text-slate-500">
                        {data.data_therapist_1
                          ? data.data_therapist_1.name
                          : "-"}
                      </span>
                      {data.data_therapist_2 && (
                        <span className="text-sm text-slate-500">
                          {data.data_therapist_2.name}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* doctor note */}
            {data.doctor_note && data.doctor_note.length > 0 && (
              <div className="col-start-start w-full gap-1">
                <div className="row-start-center gap-1 w-full pr-1">
                  <div className="bg-slate-300 w-[0.5rem] h-[2px]" />
                  <span className="text-sm font-semibold text-slate-400">
                    Doctor's Note
                  </span>
                  <div className="grow-1 bg-slate-300 h-[2px]" />
                </div>
                <div className="col-start-start gap-1 pl-1">
                  <span className="text-sm text-slate-500">
                    {data.doctor_note ? data.doctor_note : "-"}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default ListAppointmentItem;
