import React, { useContext, useEffect, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import useAPIRequest from "../custom_hooks/simple/useAPIRequest";
import ListAppointmentItem from "../pages/dashboard/transaksi/appointment/ListAppointmentItem";
import { ProgressSpinner } from "primereact/progressspinner";

const bgColors = [
  "bg-green-300",
  "bg-yellow-300",
  "bg-purple-300",
  "bg-blue-300",
  "bg-red-300",
  "bg-pink-300",
  "bg-indigo-300",
  "bg-cyan-300",
  "bg-rose-300",
  "bg-emerald-300",
];

const textColors = [
  "text-green-500",
  "text-yellow-500",
  "text-purple-500",
  "text-blue-500",
  "text-red-500",
  "text-pink-500",
  "text-indigo-500",
  "text-cyan-500",
  "text-rose-500",
  "text-emerald-500",
];

const title = "Appointment";

const AppointmentDetailDialog = (props) => {
  const { data } = props;
  const [appointment, setAppointment] = useState();

  const { loading, requestGet } = useAPIRequest();

  useEffect(() => {
    if (props.visible) {
      if (data.crm_schedule_customer_id && data.crm_schedule_customer_id > 0) {
        fetchAppointment();
      }
    }
  }, [props.visible]);

  const fetchAppointment = async () => {
    await requestGet({
      fullUrl: "api/crm/crmschedulecustomer/data",
      params: {
        take: 10000,
        page: 1,
        order: "transaction_date",
        order_method: "ASC",
        filter: `id:=:${data.crm_schedule_customer_id}'`,
        with_customer: 1,
        with_bc: 1,
        with_therapist_1: 1,
        with_therapist_2: 1,
        with_therapist_3: 1,
        with_nurse_1: 1,
        with_nurse_2: 1,
        with_nurse_3: 1,
        with_doctor: 1,
        with_products: 1,
        with_crm_options: 1,
      },
      onSuccess: ({ data }) => {
        if (data && data.length > 0) {
          setAppointment(data[0]);
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const dialogFooter = (
    <div className="col-center-center w-100">
      <Button
        className="w-full"
        label="Okay"
        onClick={(e) => {
          e.preventDefault();
          props.onHide();
        }}
      />
    </div>
  );

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[25rem] `}
      footer={dialogFooter}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className="col-center-center w-full gap-3 pt-0 pb-6">
        {!appointment && (
          <div className="col-center-center pt-2">
            <ProgressSpinner />
          </div>
        )}
        {appointment && (
          <ListAppointmentItem
            key={1}
            event={{ data: appointment, date: appointment.transaction_date }}
            backgroundColor={bgColors[1 % bgColors.length]}
            textColor={textColors[1 % textColors.length]}
            onClick={() => {}}
          />
        )}
      </div>
    </Dialog>
  );
};

export default AppointmentDetailDialog;
