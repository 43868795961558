import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useContext, useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import useAPIRequest from "../../../custom_hooks/simple/useAPIRequest";
import TableAutoComponent from "../../../components/complex/crud/TableAutoComponent";
import { format as dateFnsFormat, parse } from "date-fns";
import UiContext from "../../../store/ui-context";
import { useDispatch } from "react-redux";
import { setLayoutDashboardTitle } from "../../../store/ui-slice";
import { Paginator } from "primereact/paginator";
import dateTextReformat from "../../../utils/DateTextReformat";

const title = "Sales Detail Report";

const tableConfigs = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Date / Number",
    field: "sales",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Customer",
    field: "customer",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Subtotal",
    field: "sub_total",
    type: "number_plain",
    align: "right",
    table: true,
    filter: true,
  },
  {
    name: "Disc Item",
    field: "sub_discount_total",
    type: "number_plain",
    align: "right",
    table: true,
    filter: true,
  },
  {
    name: "Disc Sub",
    field: "discount_total",
    type: "number_plain",
    align: "right",
    table: true,
    filter: true,
  },
  {
    name: "Grandtotal",
    field: "grand_total",
    type: "number_plain",
    align: "right",
    table: true,
    filter: true,
  },
  {
    name: "Note",
    field: "custom_note",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Payment",
    field: "payments",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
];

const customColumns = [
  {
    field: "sales",
    component: (rowData) => {
      const { number, transaction_date } = rowData;
      return (
        <div className="col-center-start">
          <span className="">{number}</span>
          <span className="text-sm text-left text-slate-400 font-semibold">
            {dateTextReformat(transaction_date)}
          </span>
        </div>
      );
    },
  },
  {
    field: "customer",
    component: (rowData) => {
      const code = rowData.data_customer ? rowData.data_customer.code : "";
      const name = rowData.data_customer ? rowData.data_customer.name : "";
      return (
        <div className="col-center-start">
          <span className="text-sm text-slate-400 font-semibold">{code}</span>
          <span>{name}</span>
        </div>
      );
    },
  },
  {
    field: "custom_note",
    component: (rowData) => {
      const { voided_date, voided_reason, notes, edit_by, deleted_at } =
        rowData;
      return (
        <div className="col-start-start">
          {deleted_at && (
            <div className="row-start-center text-sm text-slate-400 font-semibold">
              <span>{`Voided by: ${edit_by}, at ${
                voided_date
                  ? dateTextReformat(voided_date)
                  : dateFnsFormat(new Date(deleted_at), "dd/MM/yyyy HH:mm")
              }`}</span>
            </div>
          )}
          {deleted_at && <span>{voided_reason}</span>}
          {notes && <span>{notes}</span>}
        </div>
      );
    },
  },
  {
    field: "payments",
    component: (rowData) => {
      const { payments } = rowData;

      return (
        <div className="col-start-start">
          {payments &&
            payments.length > 0 &&
            payments.split(",").map((item, index) => {
              return <span key={index}>{item.trim()}</span>;
            })}
        </div>
      );
    },
  },
];

const ReportSalesDetail = () => {
  const uiContext = useContext(UiContext);
  const toast = useRef();
  const dispatch = useDispatch();

  const [paging, setPaging] = useState({
    page: 1,
    take: 100,
    filter: "",
    total: 0,
  });
  const [listData, setListData] = useState([]);

  const [selectedDate, setSelectedDate] = useState([new Date(), new Date()]);

  const { requestGet, loading } = useAPIRequest();

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  const generateFilter = () => {
    let filter = "";

    if (selectedDate) {
      if (filter.length > 0) filter += ";";

      let dateStart = selectedDate[0];
      let dateEnd = selectedDate[1];

      if (dateStart)
        filter += `transaction_date:>=:${dateFnsFormat(
          dateStart,
          "yyyy-MM-dd 00:00:00"
        )}`;

      if (dateEnd)
        filter += `;transaction_date:<=:${dateFnsFormat(
          dateEnd,
          "yyyy-MM-dd 23:59:59"
        )}`;
    }

    return filter;
  };

  const reloadData = async ({ page, take }) => {
    if (loading) return;
    setListData([]);
    const filter = generateFilter();

    const params = {
      prefix: "sales",
      page: page,
      take: take,
      filter,
      order: "number",
      order_method: "ASC",
      with_customer: 1,
      trashed: 1,
    };

    return requestGet({
      fullUrl: "api/report",
      params,
      onSuccess: ({ pagination, message, data }) => {
        const newList = [];

        const page = pagination["page"];
        const take = pagination["take"];
        const startNumber = (page - 1) * take;

        data.forEach((item, index) => {
          newList.push({
            ...item,
            no: startNumber + index + 1,
          });
        });

        setPaging({
          ...paging,
          total: pagination["total"],
          page: pagination["page"],
          take: pagination["take"],
        });
        setListData(newList);
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });
  };

  const handleReload = () => {
    reloadData({ page: 1, take: 100 });
  };

  const handleOnPage = (e) => {
    const newPage = e.first / paging.take + 1;
    const newTake = e.rows;

    setPaging({ ...paging, take: newTake, page: newPage });
    reloadData({ page: newPage, take: newTake });
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <Toast ref={toast} />
      <div></div>
      <div className="elevated-card-no-padding w-100 row-start-start relative wrap gap-8">
        <div className="row-start-start wrap gap-8 w-100 p-[1rem]">
          {/* field selector selectedDate */}
          <div className={`col-start-start gap-1`}>
            <label
              htmlFor={"selectedDate"}
              className="font-bold text-sm text-zinc-500"
            >
              Transaction Date
            </label>
            <Calendar
              id="selectedDate"
              value={selectedDate}
              showButtonBar
              selectionMode="range"
              // hourFormat="24"
              showIcon
              onChange={(e) => {
                setSelectedDate(e.value);
              }}
              className={`dateInput w-[17rem]`}
              locale="id"
            />
          </div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20`}
      >
        <div className="row-between-center"></div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-file-excel"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-print"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          />
        </div>
      </div>

      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <TableAutoComponent
            onSelectionChange={() => {}}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            onEditRow={async (row) => {}}
            onDeleteRow={(row) => {}}
            customColumns={customColumns}
          />
          <Paginator
            first={paging.take * (paging.page - 1)}
            rows={paging.take}
            totalRecords={paging.total}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            onPageChange={handleOnPage}
          />
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default ReportSalesDetail;
