import { subDays, addDays } from "date-fns";

const EmployeeConfigs = {
  master: {
    title: "Employee",
    apiName: "masterdata/employee",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: {
      // add_params
      with_branch: 1,
      with_user: 1,
    },
  },
  dialog: {
    title: "Employee",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Branch",
      field: "branch_id",
      type: "object",
      object_name: "data_branch",
      object_field: "name",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Image",
      field: "image",
      default_value: null,
      type: "zirvu_file_manager",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Code",
      field: "code",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Name",
      field: "name",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Email",
      field: "email",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Phone 1",
      field: "phone_1",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Phone 2",
      field: "phone_2",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Join Date",
      field: "join_date",
      type: "date",
      align: "center",
      table: false,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
    },
    {
      name: "Birth Date",
      field: "birth_date",
      type: "date",
      align: "center",
      table: true,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
    },
    {
      name: "Retire Date",
      field: "retire_date",
      type: "date",
      align: "center",
      table: true,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
    },
    {
      name: "User Type",
      field: "user_type",
      type: "number_dropdown",
      align: "left",
      default_value: 4,
      options: [
        { name: "IT", value: 1 },
        { name: "Owner", value: 2 },
        { name: "BC", value: 3 },
        { name: "Admin", value: 4 },
        { name: "Doctor", value: 5 },
        { name: "GM", value: 6 },
        { name: "Cashier", value: 8 },
        { name: "Therapist", value: 9 },
        { name: "Nurse", value: 10 },
        { name: "Finance", value: 11 },
      ],
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "User Name",
      field: "data_user.username",
      type: "text_plain",
      editor: true,
    },
    {
      name: "Password",
      field: "data_user.password",
      default_value: "",
      default_value_on_edit: true,
      type: "text_password",
      editor: true,
    },
    {
      name: "Konfirmasi Password",
      field: "data_user.cpassword",
      default_value: "",
      default_value_on_edit: true,
      type: "text_password",
      editor: true,
    },
  ],
};

export default EmployeeConfigs;
